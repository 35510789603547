import { Tour } from '../../../../../../types';
import formatPrice from '../../../../../../utils/formatPrice/formatPrice';
import Image from '../../../../../../components/Image/Image';
import UnitTourInfo from '../../../../components/UnitTourInfo/UnitTourInfo';
import useIsMobile from '../../../../../../hooks/useIsMobile/useIsMobile';

import styles from './InfoMenuContent.module.scss';

interface InfoMenuContentProps {
  tour: Tour;
}

export default function InfoMenuContent({ tour }: InfoMenuContentProps) {
  const floorplanConfig = tour.floorplan[0];
  const isMobileLandscape = useIsMobile('landscape');

  return (
    <div
      className={`${styles.container} ${
        isMobileLandscape ? styles.mobileLandscape : ''
      }`}
    >
      <div className={styles.topContent}>
        <div className={styles.unitName}>{tour.title}</div>
        {tour.unitTour.rent?.maxRent && (
          <div className={styles.price}>
            {formatPrice(tour.unitTour.rent.maxRent)}/mo
          </div>
        )}

        <UnitTourInfo
          className={styles.unitTourInfo}
          unitTour={tour.unitTour}
          //priceVisible={false}
        />
      </div>
      <div className={styles.bottomContent}>
        {floorplanConfig ? (
          <Image
            alt="Floor plan"
            className={styles.floorplanImage}
            src={floorplanConfig.img || floorplanConfig.image!.url}
          />
        ) : null}
      </div>
    </div>
  );
}
