import { FunctionComponent, useMemo } from 'react';
import clsx from 'clsx';

import { makeMenuButtonID, MENU_BUTTONS_ID } from '../../../../constants/ids';
import { MenuButton, MenuItemType, Tour } from '../../../../types';
import { Theme } from '../../../../types/theme';
import useIsMobile from '../../../../hooks/useIsMobile/useIsMobile';
import useMenuItemContent from '../../hooks/useMenuItemContent/useMenuItemContent';
import useTourFunctionality from '../../../../hooks/useTourFunctionality/useTourFunctionality';
import useURLParam, {
  HIDE_BUTTONS_SEPARATOR,
  OverlayURLParam,
} from '../../../../hooks/useURLParam/useURLParam';

import { ReactComponent as Contact } from './assets/contact.svg';
import { ReactComponent as Featured } from './assets/featured.svg';
import { ReactComponent as Info } from './assets/info.svg';
import { ReactComponent as Map } from './assets/map.svg';
import { ReactComponent as Share } from './assets/share.svg';
import { ReactComponent as ShareLive } from '../BottomBar/assets/share.svg';

import styles from './Menu.module.scss';

export const ICONS_CONFIG: Record<string, FunctionComponent> = {
  'icon-tour-info': Info,
  'icon-book-now': Info,
  'fa-info': Info,
  'icon-contact': Contact,
  'icon-floorplan': Featured,
  'icon-share': Share,
  'fa-map-marker': Map,
  'custom-share-live': ShareLive,
};

interface FontAwesomeIconProps {
  icon: string;
}

function FontAwesomeIcon({ icon }: FontAwesomeIconProps) {
  return (
    <i
      className={clsx(styles.fontAwesomeIcon, 'fa', icon)}
      aria-hidden="true"
    ></i>
  );
}

interface MenuItemsProps {
  menuItems: MenuButton[];
  onItemClick(id: string, type: MenuItemType, conversion: boolean): void;
  openedMenuItemId: string;
}

function MenuItems({
  menuItems,
  onItemClick,
  openedMenuItemId,
}: MenuItemsProps) {
  const hideButtons = useURLParam(OverlayURLParam.HIDE_BUTTONS);

  let hiddenButtons = useMemo(() => {
    return hideButtons.split(HIDE_BUTTONS_SEPARATOR);
  }, [hideButtons]);
  return (
    <div className={styles.menu} id={MENU_BUTTONS_ID}>
      {menuItems
        .filter((item) => item.type !== 'fullscreen')
        .filter((item) => !hiddenButtons.includes(item.text))
        .map((item) => {
          const icon = item.icon;
          const Icon = ICONS_CONFIG[icon] || Info;
          // Need to exclude default fa-icons for which we have designed specific theme icons.
          const isFontAwesomeIcon =
            icon.startsWith('fa-') &&
            icon !== 'fa-map-marker' &&
            icon !== 'fa-info';

          return (
            <div
              className={`tb-menu-item ${styles.menuItem} ${
                item.id === openedMenuItemId ? styles.menuActive : ''
              }`}
              data-cy={`menu-item-${item.text}`}
              key={item.id}
              onClick={() => onItemClick(item.id, item.type, item.conversion)}
              title={item.text}
              id={makeMenuButtonID(item.id)}
            >
              {isFontAwesomeIcon ? <FontAwesomeIcon icon={icon} /> : <Icon />}
            </div>
          );
        })}
    </div>
  );
}

interface MenuProps {
  theme: Theme;
  tour: Tour;
}

export default function Menu({ theme, tour }: MenuProps) {
  const { availableButtons } = useTourFunctionality(tour, theme);
  const isMobile = useIsMobile();
  const { openMenuItem, openedMenuItemId } = useMenuItemContent();

  return !isMobile ? (
    <MenuItems
      menuItems={availableButtons}
      onItemClick={openMenuItem}
      openedMenuItemId={openedMenuItemId}
      data-cy="menu-bar"
    />
  ) : null;
}
