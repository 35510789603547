import { CSSProperties, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Color from 'color';

import useURLParam, {
  OverlayURLParam,
} from '../../hooks/useURLParam/useURLParam';
import AnalyticsScripts from '../AnalyticsScripts/AnalyticsScripts';
import ColorsDebugger from '../ColorsDebugger/ColorsDebugger';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import loadScriptsSerially from '../../utils/loadScriptsSerially/loadScriptsSerially';
import Theme from '../../themes/Theme';
import useAppHeightFix from '../../hooks/useIOSAppHeightFix/useIOSAppHeightFix';
import useGoogleFont from '../../hooks/useGoogleFont/useGoogleFont';
import useHideUi from '../../hooks/useHideUi/useHideUi';
import useTheme from '../../hooks/useTheme/useTheme';
import useTour from '../../hooks/useTour/useTour';
import useTourFunctionality from '../../hooks/useTourFunctionality/useTourFunctionality';
import useViewer from '../../hooks/useViewer/useViewer';

import styles from './App.module.scss';

// https://www.w3.org/TR/WCAG20/#contrast-ratiodef
const BACKGROUND_CONTRAST_RATIO = 3;
const WHITE = Color('white');

async function* legacyScripts() {
  yield 'https://code.jquery.com/jquery-2.2.4.min.js';
  yield 'https://cdnjs.cloudflare.com/ajax/libs/tinyscrollbar/2.4.2/jquery.tinyscrollbar.js';
}

const App = () => {
  const family =
    useURLParam(OverlayURLParam.FAMILY) || 'Open+Sans:wght@400;500;700';
  const fontDisplay = useURLParam(OverlayURLParam.DISPLAY) || 'swap';
  const tour = useTour();
  const theme = useTheme();
  const googleFontFamily = useGoogleFont(family);
  const fontFamily = theme.template === 'v1' ? 'Noto Sans' : googleFontFamily;
  const { analyticsEnabled } = useTourFunctionality(tour, theme);
  const hideUi = useHideUi();
  const { viewer } = useViewer();

  useEffect(() => {
    if (!viewer) {
      return;
    }

    if (tour.menuButtons.some((menuButton) => menuButton.type === 'extended')) {
      loadScriptsSerially(legacyScripts);

      window.APP = {
        customViewer: {
          instance: {
            setPano: (panoId) => viewer?.setPano(panoId),
          },
        },
      };
    }
  }, [tour.menuButtons, viewer]);

  useAppHeightFix();

  useEffect(() => {
    switch (tour.carousel.start) {
      case 'video':
      case 'image':
        hideUi();
        break;
    }
  }, [tour, hideUi]);

  // TODO: add/find more explicit "theme color"
  const activeColor = theme.styles.carousel.tabs.backgroundActive;
  const carouselBackground = theme.styles.carousel.background;

  const cssVariables = {
    '--font-family': fontFamily,
    '--text-color': theme.styles.startScreen.button.color,
    '--button-background': theme.styles.startScreen.button.background,
    '--menu-background': theme.styles.menu.background,
    '--menu-button-background': theme.styles.menu.menuButton.background,
    '--menu-button-color': theme.styles.menu.menuButton.color,
    '--menu-icon-color-default': theme.styles.menu.icon.color.default,
    '--menu-icon-color-active': theme.styles.menu.icon.color.active,
    '--menu-icon-color-hover': theme.styles.menu.icon.color.hover,
    '--menu-item-background': theme.styles.menu.itemBackground,
    '--menu-item-active-background': theme.styles.menu.itemActiveBackground,
    '--carousel-background': carouselBackground,
    '--carousel-tabs-background': theme.styles.carousel.tabs.background,
    '--carousel-tabs-background-active':
      theme.styles.carousel.tabs.backgroundActive,
    '--carousel-active-border-color':
      Color(carouselBackground).contrast(WHITE) <= BACKGROUND_CONTRAST_RATIO &&
      carouselBackground !== activeColor
        ? activeColor
        : 'white',
    '--carousel-controls-color': theme.styles.carousel.controls.color,
    '--carousel-tabs-icon-color-active':
      theme.styles.carousel.tabs.icons.active.color,
    '--carousel-tabs-icon-color-default':
      theme.styles.carousel.tabs.icons.default.color,
    '--carousel-controls-background': theme.styles.carousel.controls.background,
    '--carousel-title-color': theme.styles.carousel.title.color,
    '--carousel-title-background': theme.styles.carousel.title.background,
    '--share-live-background': theme.styles.carousel.tabs.background,
    '--share-live-background-hover': Color(
      theme.styles.carousel.tabs.background
    )
      .darken(0.1)
      .hex(),
    '--stats-background': theme.styles.menu.background,
    '--stats-icon-color': theme.styles.menu.icon.color.default,
    '--pill-background': theme.styles.carousel.tabs.background,
    '--pill-background-active': activeColor,
    '--pill-icon-inactive': theme.styles.carousel.tabs.icons,
    '--modal-background': theme.styles.modal.background,
    '--modal-color': theme.styles.modal.color,
    '--modal-icon-color': theme.styles.modal.icon.color.default,
    '--modal-icon-color-hover': theme.styles.modal.icon.color.hover,
  } as CSSProperties;

  return (
    <ErrorBoundary>
      <Helmet>
        <link
          href={`https://fonts.googleapis.com/css2?family=${family}&display=${fontDisplay}`}
          rel="stylesheet"
        />
        {theme.styles.stylesheet && (
          <link href={theme.styles.stylesheet} rel="stylesheet" />
        )}
        <style type="text/css">{`
        .viewer-hotspot {
          z-index: 0;
        }
        .viewer-hotspot-title {
          font-family: ${fontFamily};
        }
        .viewer-label {
          z-index: 0;
        }
        .viewer-label .viewer-label-object .viewer-label-inner-box .viewer-label-title-container {
          font-family: ${fontFamily};
        }
        ${theme.styles.custom ?? ''}
    `}</style>
      </Helmet>
      {analyticsEnabled && (
        <AnalyticsScripts
          googleTrackingCode={process.env.REACT_APP_GOOGLE_TRACKING_CODE}
        />
      )}
      <div className={styles.app} style={cssVariables} id="app">
        {tour ? (
          <div className={styles.viewport} id="viewport">
            <ColorsDebugger cssVariables={cssVariables} />
            <Theme cssVariables={cssVariables} theme={theme} tour={tour} />
          </div>
        ) : null}
      </div>
    </ErrorBoundary>
  );
};

export default App;
