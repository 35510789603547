import React from 'react';

import { Tour } from '../../../../../../types/';
import useAnalyticsEvents from '../../../../../../hooks/useAnalyticsEvents/useAnalyticsEvents';

import styles from './ContactMenuContent.module.scss';

import { GOOGLE_MAPS_DIRECTIONS_URL } from '../../../../../../constants';

interface ContactMenuContentProps {
  tour: Tour;
}

export default function ContactMenuContent({ tour }: ContactMenuContentProps) {
  const websiteUrl = tour.info.website ? new URL(tour.info.website) : null;
  const analyticsEvents = useAnalyticsEvents();
  const directionsLinkEncoded = `${GOOGLE_MAPS_DIRECTIONS_URL}${encodeURIComponent(
    `${tour.info.address.name} ${tour.info.address.streetAddress} ${tour.info.address.city} ${tour.info.address.postalCode}`
  )}`;

  return (
    <div className={styles.container}>
      <div className={styles.title}>{tour.info.address.name || tour.title}</div>
      <div className={styles.address}>
        {tour.info.address.streetAddress} <br></br> {tour.info.address.city} ,{' '}
        {tour.info.address.state}, {tour.info.address.postalCode}
        {tour.info.website && websiteUrl ? (
          <a
            href={tour.info.website}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: 'none' }}
          >
            {websiteUrl.host}
          </a>
        ) : null}
        <div>{tour.info.phone}</div>
      </div>
      <div className={styles.actions}>
        {tour.contact.call.show ? (
          <a
            className={styles.action}
            href={`tel:${tour.info.phone}`}
            onClick={() => {
              analyticsEvents.conversion('call', 'Call');
            }}
          >
            <i className={`${styles.fontAwesomeIcon} fa fa-phone-square `}></i>
            Call
          </a>
        ) : null}
        {tour.contact.directions.show ? (
          <a
            className={styles.action}
            href={directionsLinkEncoded}
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              analyticsEvents.conversion('directions', 'Directions');
            }}
          >
            <i className={`${styles.fontAwesomeIcon} fa fa-car`}></i>
            Directions
          </a>
        ) : null}
      </div>
    </div>
  );
}
