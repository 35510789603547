import { animated, useSpring } from '@react-spring/web';

import useURLParam, {
  OverlayURLParam,
} from '../../../../hooks/useURLParam/useURLParam';
import { Theme } from '../../../../types/theme';
import { CarouselItemType } from '../../../../types/carousel';
import { Tour } from '../../../../types';
import { useAppSelector } from '../../../../hooks/redux';
import hexToRGB, { HEX } from '../../../../utils/hexToRGB/hexToRGB';
import Menu from '../Menu/Menu';
import SceneSelector from '../SceneSelector/SceneSelector';
import ShareLive from '../../../../components/ShareLive/ShareLive';
import UnitTourInfo from '../UnitTourInfo/UnitTourInfo';
import useIsMobile from '../../../../hooks/useIsMobile/useIsMobile';
import useMediaQuery from '../../../../hooks/useMediaQuery/useMediaQuery';
import ViewControls from '../ViewControls/ViewControls';

import styles from './BottomBar.module.scss';

import { ReactComponent as ShareLiveIcon } from './assets/share.svg';

interface BottomBarProps {
  availableTabs: CarouselItemType[];
  backgroundColor: string;
  theme: Theme;
  tour: Tour;
  floorPlanModeEnabled: boolean;
  initialPanoId: string;
}

const EmptyIcon = () => null;

export default function BottomBar({
  tour,
  theme,
  availableTabs,
  backgroundColor,
  floorPlanModeEnabled,
  initialPanoId,
}: BottomBarProps) {
  const hideShareLive = useURLParam(OverlayURLParam.HIDE_SHARE_LIVE) === 'true';
  const hidenCarousel = useURLParam(OverlayURLParam.HIDE_CAROUSEL);
  const hideRightMenu = Boolean(useURLParam(OverlayURLParam.HIDE_RIGHT_MENU));
  const RGBABackgroundColor = hexToRGB(backgroundColor as HEX, 0.85);
  const isMobile = useIsMobile();
  const isMobilePortrait = useIsMobile('portrait');
  const isShareLiveIconVisible = !useMediaQuery('lg');
  const isShareLiveHidden = useMediaQuery('md') || hideShareLive;
  const media = useAppSelector((s) => s.media);
  const isCarouselEnabled =
    hidenCarousel || tour.carousel?.start === 'false' ? false : true;

  const bottomBarAnimation = useSpring({
    bottom: !isMobile ? (media.type === 'pano' ? 0 : -95) : isMobile ? 0 : 95,
  });

  return (
    <animated.div
      style={bottomBarAnimation}
      className={styles.bottomBar}
      data-cy="bottom-bar"
    >
      <div className={styles.topRow}>
        {isMobilePortrait && isCarouselEnabled ? (
          <SceneSelector
            availableTabs={availableTabs}
            config={tour.carousel}
            initialPanoId={initialPanoId}
            theme={theme}
          />
        ) : null}
        <ViewControls theme={theme} tour={tour} />
      </div>
      <div
        className={styles.bottomRow}
        style={{
          backgroundColor: RGBABackgroundColor,
        }}
      >
        {!isMobilePortrait ? (
          <div>
            {isCarouselEnabled ? (
              <SceneSelector
                availableTabs={availableTabs}
                config={tour.carousel}
                initialPanoId={initialPanoId}
                theme={theme}
              />
            ) : null}
            {!hideRightMenu ? <Menu theme={theme} tour={tour} /> : null}
          </div>
        ) : null}

        <div>
          {!isShareLiveHidden ? (
            <ShareLive
              className={styles.shareLive}
              clientId={tour.client._id}
              tourId={tour._id}
              themeId={tour.themeId}
              icon={isShareLiveIconVisible ? ShareLiveIcon : EmptyIcon}
            />
          ) : null}
          {tour.tourType !== 'property' && (
            <UnitTourInfo
              className={styles.unitTourInfo}
              unitTour={tour.unitTour}
              joinPrimaryDetails={isMobile}
              hideUnitInformation={floorPlanModeEnabled}
            />
          )}
        </div>
      </div>
    </animated.div>
  );
}
