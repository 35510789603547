import { CSSProperties, useEffect, useState, useMemo } from 'react';

import useURLParam, {
  OverlayURLParam,
} from '../../hooks/useURLParam/useURLParam';
import { MenuItemContentProvider } from './hooks/useMenuItemContent/useMenuItemContent';
import { setControlsVisible } from '../../stores/slices/ui';
import { ThemeProps } from '../Theme';
import { useAppDispatch } from '../../hooks/redux';
import { useAppSelector } from '../../hooks/redux';
import { useNavigateToPhoto } from '../../hooks/useNavigateToPhoto/useNavigateToPhoto';
import { useNavigateToVideo } from '../../hooks/useNavigateToVideo/useNavigateToVideo';
import Carousel from './components/Carousel/Carousel';
import FloorPlan from '../../components/FloorPlan/FloorPlan';
import FloorPlanMobileControls from './components/FloorPlanMobileControls/FloorPlanMobileControls';
import GlobalCss from './GlobalCss';
import MediaImage from '../../components/MediaImage/MediaImage';
import MediaImageControls from '../../components/MediaImageControls/MediaImageControls';
import MediaVideo from '../../components/MediaVideo/MediaVideo';
import Pano from './components/Pano/Pano';
import ShareLive from '../../components/ShareLive/ShareLive';
import StartScreen from '../../components/StartScreen/StartScreen';
import useAnalyticsEvents from '../../hooks/useAnalyticsEvents/useAnalyticsEvents';
import useIsMobile from '../../hooks/useIsMobile/useIsMobile';
import useIsViewportTooLow from './hooks/useIsViewportTooLow/useIsViewportTooLow';
import useTourFunctionality from '../../hooks/useTourFunctionality/useTourFunctionality';
import useViewer from '../../hooks/useViewer/useViewer';

const CLIENTS_WITH_VIEW_CONTROLS_OPENED_BY_DEFAULT = [
  '56c3a81809a3adea4c46c794',
  '6490e2d6f6a1ae70fc12c850',
];

export default function TourBuilderProTheme({
  cssVariables,
  theme,
  tour,
}: ThemeProps) {
  const dispatch = useAppDispatch();
  const [showStart, setShowStart] = useState(true);
  const disableStartScreen =
    useURLParam(OverlayURLParam.START_SCREEN) === 'false';
  const analyticsEvents = useAnalyticsEvents();
  const { availableTabs, logo, initialCarouselItemType, isCarouselEnabled } =
    useTourFunctionality(tour, theme);
  const hideRightMenu = useURLParam(OverlayURLParam.HIDE_RIGHT_MENU) === 'true';
  const hideShareLive = useURLParam(OverlayURLParam.HIDE_SHARE_LIVE) === 'true';
  const { panoId } = useViewer();
  const media = useAppSelector((s) => s.media);

  useEffect(() => {
    if (!panoId || (tour.startScreen.show && !disableStartScreen)) {
      return;
    }

    analyticsEvents.tourVisit();

    switch (media.type) {
      case 'pano':
        if (panoId) {
          analyticsEvents.panoramaChanged(panoId);
        }
        break;
      case 'image':
        analyticsEvents.imageGalleryView(
          media.imageId,
          media.source.url,
          media.imageTitle
        );
        break;
      case 'video':
        analyticsEvents.videoGalleryView(
          media.source.videoId,
          media.source.title
        );
        break;
    }
  }, [analyticsEvents, disableStartScreen, media, tour, panoId]);

  useEffect(() => {
    if (
      CLIENTS_WITH_VIEW_CONTROLS_OPENED_BY_DEFAULT.includes(tour.client._id) &&
      tour.tourType === 'property'
    ) {
      dispatch(setControlsVisible(true));
    }
  }, [dispatch, tour.client._id, tour.tourType]);

  const { navigateToPhoto: navigateToPhotoLeft } = useNavigateToPhoto(
    'left',
    tour.carousel.image
  );
  const { navigateToPhoto: navigateToPhotoRight } = useNavigateToPhoto(
    'right',
    tour.carousel.image
  );
  const { navigateToVideo: navigateToVideoLeft } = useNavigateToVideo(
    'left',
    tour.carousel.video
  );
  const { navigateToVideo: navigateToVideoRight } = useNavigateToVideo(
    'right',
    tour.carousel.video
  );

  const isMobile = useIsMobile();
  const isLandscape = useIsMobile('landscape');
  const { isViewportTooLow } = useIsViewportTooLow(tour);

  const floorPlanPanoIds = useMemo(() => {
    return tour.floorplan.flatMap((floorplan) =>
      floorplan.hotspots.map((hotspot) => hotspot.panoid)
    );
  }, [tour.floorplan]);

  return (
    <>
      <GlobalCss />
      <MenuItemContentProvider>
        <div
          data-cy="tb-default-theme"
          id="theme"
          style={
            {
              '--button-border-radius': '10px',
              '--button-border-radius-small': '5px',
              '--controls-border-radius': '50px',
            } as CSSProperties
          }
        >
          {media.type === 'pano' && (
            <FloorPlan
              configs={tour.floorplan}
              sliders={tour.sliders}
              additionalControls={
                isMobile || isViewportTooLow ? (
                  <FloorPlanMobileControls
                    theme={theme}
                    tour={tour}
                    isLandscape={isLandscape || isViewportTooLow}
                  />
                ) : null
              }
            />
          )}
          {media.type === 'pano' && (
            <Pano hideRightMenu={hideRightMenu} tour={tour} theme={theme} />
          )}
          {media.type === 'image' && (
            <>
              <MediaImage
                key={media.source.url}
                config={media}
                showArrows={tour.carousel.image.length > 1}
                arrowVariant="tb-pro"
                onNavigateLeft={navigateToPhotoLeft}
                onNavigateRight={navigateToPhotoRight}
                cssVariables={cssVariables}
              />
              <MediaImageControls tour={tour} />
            </>
          )}
          {media.type === 'video' && (
            <MediaVideo
              arrowVariant="tb-pro"
              cssVariables={cssVariables}
              onNavigateLeft={navigateToVideoLeft}
              onNavigateRight={navigateToVideoRight}
              showArrows={tour.carousel.video.length > 1}
              source={media.source}
              tourId={tour._id}
            />
          )}
          {isCarouselEnabled && (
            <Carousel
              availableTabs={availableTabs}
              config={tour.carousel}
              initialItemType={initialCarouselItemType}
              pillColors={{
                default: theme.styles.carousel.tabs.icons.default.color,
                active: theme.styles.carousel.tabs.icons.active.color,
              }}
              floorPlanPanoIds={floorPlanPanoIds}
              keepControlsOpenedBetweenTabs={CLIENTS_WITH_VIEW_CONTROLS_OPENED_BY_DEFAULT.includes(
                tour.client._id
              )}
            />
          )}
          {showStart && !disableStartScreen && (
            <StartScreen
              config={tour.startScreen}
              onStart={() => {
                analyticsEvents.tourVisit();

                switch (media.type) {
                  case 'pano':
                    if (panoId) {
                      analyticsEvents.panoramaChanged(panoId);
                    }
                    break;
                  case 'image':
                    analyticsEvents.imageGalleryView(
                      media.imageId,
                      media.source.url,
                      media.imageTitle
                    );
                    break;
                  case 'video':
                    analyticsEvents.videoGalleryView(
                      media.source.videoId,
                      media.source.title
                    );
                    break;
                }

                setShowStart(false);
              }}
              logo={logo}
              poweredBy={tour.poweredByLCP360}
              showAccessibilityStatement={true}
            />
          )}
          {!hideShareLive && (
            <ShareLive
              clientId={tour.client._id}
              tourId={tour._id}
              themeId={tour.themeId}
            />
          )}
        </div>
      </MenuItemContentProvider>
    </>
  );
}
