import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom/client';

import { store } from './stores/store';
import { ViewerProvider } from './hooks/useViewer/useViewer';
import App from './components/App/App';
import reportWebVitals from './reportWebVitals';

import './index.scss';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
    <ViewerProvider>
      <App />
    </ViewerProvider>
  </Provider>
);

reportWebVitals(console.log);
