import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import styles from './MenuButtons.module.scss';
import {
  MenuButton as IMenuButton,
  MenuItemType,
  Tour,
} from '../../../../types';

import { ReactComponent as Info } from './assets/info.svg';
import { ReactComponent as Share } from './assets/share.svg';
import { ReactComponent as Contact } from './assets/contact.svg';
import { ReactComponent as Floorplan } from './assets/floorplan.svg';
import { ReactComponent as Fullscreen } from './assets/fullscreen.svg';
import { ReactComponent as Map } from './assets/map.svg';
import { ReactComponent as Up } from './assets/up.svg';
import { ReactComponent as Down } from './assets/down.svg';
import useIsMobile from '../../../../hooks/useIsMobile/useIsMobile';
import useIsViewportTooLow from '../../hooks/useIsViewportTooLow/useIsViewportTooLow';
import onEnter from '../../../../utils/onEnter/onEnter';
import useWindowSize from '../../../../hooks/useWindowSize/useWindowSize';
import clsx from 'clsx';
import { makeMenuButtonID, MENU_BUTTONS_ID } from '../../../../constants/ids';

const SCROLL_STEP = 100;

const ICONS_CONFIG: Record<string, FunctionComponent> = {
  'icon-tour-info': Info,
  'fa-info': Info,
  'icon-book-now': Info,
  'icon-floorplan': Floorplan,
  'icon-share': Share,
  'icon-contact': Contact,
  'icon-fullscreen': Fullscreen,
  'icon-closefullscreen': Fullscreen,
  'fa-map-marker': Map,
};

interface FontAwesomeIconProps {
  icon: string;
}

function FontAwesomeIcon({ icon }: FontAwesomeIconProps) {
  return (
    <i
      className={clsx(styles.fontAwesomeIcon, 'fa', icon)}
      aria-hidden="true"
    ></i>
  );
}

interface MenuButtonProps {
  text: string;
  icon: string;
  id: string;
  onClick: () => void;
}

const MenuButton = React.memo(
  ({ text, icon, id, onClick }: MenuButtonProps) => {
    const Icon = ICONS_CONFIG[icon] || Info;
    const isFontAwesomeIcon =
      icon.startsWith('fa-') && icon !== 'fa-info' && icon !== 'fa-map-marker';
    const isMobile = useIsMobile();

    return (
      <div
        className={styles.control}
        data-cy={`menu-button-${id}`}
        id={makeMenuButtonID(id)}
        onClick={onClick}
        onKeyDown={onEnter(onClick)}
        role="button"
        tabIndex={0}
      >
        <div className={styles.menuButton}>
          {isFontAwesomeIcon ? <FontAwesomeIcon icon={icon} /> : <Icon />}
          {!isMobile ? <span className={styles.label}>{text}</span> : null}
        </div>
      </div>
    );
  }
);

interface MenuButtonsProps {
  tour: Tour;
  menuButtons: IMenuButton[];
  onMenuButtonClick(id: string, type: MenuItemType, conversion: boolean): void;
}

export default function MenuButtons({
  tour,
  menuButtons,
  onMenuButtonClick,
}: MenuButtonsProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [canScrollUp, setCanScrollUp] = useState(false);
  const [canScrollDown, setCanScrollDown] = useState(false);

  const { isViewportTooLow, menuHeight } = useIsViewportTooLow(tour);
  const isMobilePortrait = useIsMobile('portrait');
  const isMobile = useIsMobile();
  const windowSize = useWindowSize();
  const menuBottomDistance = isMobilePortrait ? 300 : isMobile ? 140 : 94;

  const maxHeight = isViewportTooLow
    ? (windowSize?.height || menuHeight) - menuBottomDistance
    : 'auto';

  const checkScrollability = () => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      setCanScrollUp(scrollTop > 0);
      setCanScrollDown(scrollTop < scrollHeight - clientHeight - 1);
    }
  };

  const scroll = (direction: 'up' | 'down') => {
    if (containerRef.current) {
      const currentScroll = containerRef.current.scrollTop;
      const newScroll =
        direction === 'up'
          ? currentScroll - SCROLL_STEP
          : currentScroll + SCROLL_STEP;
      containerRef.current.scrollTo({ top: newScroll, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    checkScrollability();
  }, [windowSize?.height, menuButtons]);

  return (
    <div
      ref={containerRef}
      id={MENU_BUTTONS_ID}
      className={styles.container}
      style={{
        minHeight: 100,
        maxHeight,
      }}
      onScroll={checkScrollability}
    >
      <div
        className={clsx(styles.arrow, styles.arrowUp, {
          [styles.arrowVisible]: canScrollUp,
        })}
        onClick={() => scroll('up')}
      >
        <div>
          <Up />
        </div>
      </div>
      {menuButtons
        .filter((menuButton) => menuButton.type !== 'fullscreen')
        .map((menuButton) => {
          return (
            <MenuButton
              text={menuButton.text}
              icon={menuButton.icon}
              id={menuButton.id}
              key={menuButton.id}
              onClick={() =>
                onMenuButtonClick(
                  menuButton.id,
                  menuButton.type,
                  menuButton.conversion
                )
              }
            />
          );
        })}
      <div
        className={clsx(styles.arrow, styles.arrowDown, {
          [styles.arrowVisible]: canScrollDown,
        })}
        onClick={() => scroll('down')}
      >
        <div>
          <Down />
        </div>
      </div>
    </div>
  );
}
