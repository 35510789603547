import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { normalizeTour } from '../../utils/normalizeTour/normalizeTour';
import { showImage, showVideo } from '../../stores/slices/media';
import { Tour } from '../../types';
import { useAppDispatch } from '../redux';
import { VIEWER_CONTAINER_ID } from '../../constants';
import useAnalyticsEvents from '../useAnalyticsEvents/useAnalyticsEvents';
import useTheme from '../useTheme/useTheme';
import useTour from '../useTour/useTour';
import useTourFunctionality from '../useTourFunctionality/useTourFunctionality';
import useURLParam, { OverlayURLParam } from '../useURLParam/useURLParam';

import arrow from './assets/arrow.svg';
import decagonArrow from './assets/decagon.svg';
import googleArrow from './assets/google-arrow.svg';
import haloArrow from './assets/halo.svg';

interface ViewerContextValue {
  viewer: Panoskin.ViewerInstance | null;
  panoId?: string;
  pov?: Panoskin.POV;
  isSliderAvailable: boolean;
  sliderOpacity: number;
  setSliderOpacity?(opacity: number): void;
  setPov?(pov: Panoskin.POV): void;
}

const DEFAULT_POV: Panoskin.POV = { heading: 0, pitch: 0, zoom: 1 };
const INITIAL_SLIDER_OPACITY = 0;

const ViewerContext = createContext<ViewerContextValue>({
  viewer: null,
  isSliderAvailable: false,
  sliderOpacity: INITIAL_SLIDER_OPACITY,
});

interface ProviderProps {
  children: ReactNode;
}

const ERROR_SAFE_VIEWER_FUNCTIONS = [
  'createHotspot',
  'createLabel',
  'createPolygon',
  'createSlider',
  'deleteHotspot',
  'deleteLabel',
  'deletePolygon',
  'getPano',
  'getPov',
  'isSliderAvailable',
  'setPano',
  'setPov',
  'setSliderOpacity',
] as const;

const suppressErrorsDuringTransition = function (fn: Function) {
  return function () {
    try {
      // @ts-ignore
      return fn.apply(this, arguments);
    } catch (err) {
      console.error(err);
    }
  };
};

const ARROW_TO_PATH_MAP: Record<Tour['navigationArrows'], string> = {
  arrow: arrow,
  decagon: decagonArrow,
  google: googleArrow,
  halo: haloArrow,
};

export function ViewerProvider({ children }: ProviderProps) {
  const customInitialHeading = useURLParam(OverlayURLParam.HEADING);
  const customInitialPitch = useURLParam(OverlayURLParam.PITCH);
  const customInitialZoom = useURLParam(OverlayURLParam.ZOOM);

  const tour = useTour();
  const theme = useTheme();
  const [viewer, setViewer] = useState<Panoskin.ViewerInstance | null>(null);

  const [panoId, setPanoId] = useState<string | undefined>(undefined);
  const [pov, setPov] = useState<Panoskin.POV | undefined>();
  const [isSliderAvailable, setIsSliderAvailable] = useState(false);
  const [sliderOpacity, setSliderOpacity] = useState(INITIAL_SLIDER_OPACITY);

  const { initialPanoId } = useTourFunctionality(tour, theme);
  const analyticsEvents = useAnalyticsEvents();

  const dispatch = useAppDispatch();

  const updatePov = useCallback(() => {
    const newPov = viewer?.getPov();

    setPov(newPov);
  }, [viewer]);

  const updatePanoId = useCallback(() => {
    const newPanoId = viewer?.getPano();

    const isSliderAvailable = !!viewer?.isSliderAvailable();

    setIsSliderAvailable(isSliderAvailable);
    isSliderAvailable && viewer?.setSliderOpacity(sliderOpacity);

    setPanoId(newPanoId);

    if (newPanoId !== panoId && panoId !== undefined) {
      analyticsEvents.panoramaChanged(newPanoId!);
    }
  }, [analyticsEvents, panoId, sliderOpacity, viewer]);

  useEffect(() => {
    const initialPov: Panoskin.POV = {
      heading:
        customInitialHeading && !isNaN(Number(customInitialHeading))
          ? Number(customInitialHeading)
          : tour.carousel.panoStart.pov?.heading ?? DEFAULT_POV.heading,
      pitch:
        customInitialPitch && !isNaN(Number(customInitialPitch))
          ? Number(customInitialPitch)
          : tour.carousel.panoStart.pov?.pitch ?? DEFAULT_POV.pitch,
      zoom:
        customInitialZoom && !isNaN(Number(customInitialZoom))
          ? Number(customInitialZoom)
          : tour.carousel.panoStart.pov?.zoom ?? DEFAULT_POV.zoom,
    };

    const viewer = new Panoskin.Viewer().mount({
      arrow: ARROW_TO_PATH_MAP[tour.navigationArrows],
      containerID: VIEWER_CONTAINER_ID,
      startPanoID: initialPanoId,
      datafeed: normalizeTour(tour),
    });

    viewer.setLinkMode(tour.navigationArrowsMode);
    viewer.setPov(initialPov);

    setPanoId(initialPanoId);

    switch (tour.carousel.start) {
      case 'image':
        const [initialImage] = tour.carousel.image;

        if (initialImage) {
          dispatch(
            showImage({
              imageId: initialImage.imageId,
              imageTitle: initialImage.title,
              source: initialImage.image,
            })
          );
        }
        break;
      case 'video':
        const [initialVideo] = tour.carousel.video;

        if (initialVideo) {
          dispatch(showVideo(initialVideo));
        }
        break;
    }

    ERROR_SAFE_VIEWER_FUNCTIONS.forEach((functionName) => {
      if (typeof viewer[functionName] === 'function') {
        viewer[functionName] = suppressErrorsDuringTransition(
          viewer[functionName]
        );
      }
    });

    setViewer(viewer);

    // directly expose viewer instance to Cypress
    if ('Cypress' in window) {
      (window as any).viewer = viewer;
    }
  }, [
    analyticsEvents,
    customInitialHeading,
    customInitialPitch,
    customInitialZoom,
    dispatch,
    initialPanoId,
    tour,
  ]);

  useEffect(() => {
    viewer?.addEventListener('pano_changed', updatePanoId);
    viewer?.addEventListener('pov_changed', updatePov);

    updatePov();
    updatePanoId();

    return () => {
      viewer?.removeEventListener('pano_changed', updatePanoId);
      viewer?.removeEventListener('pov_changed', updatePov);
    };
  }, [updatePanoId, updatePov, viewer]);

  return (
    <ViewerContext.Provider
      value={{
        isSliderAvailable,
        panoId,
        pov,
        sliderOpacity,
        setSliderOpacity: (opacity: number) => {
          setSliderOpacity(opacity);
          viewer?.setSliderOpacity(opacity);
        },
        viewer,
        setPov,
      }}
    >
      {children}
    </ViewerContext.Provider>
  );
}

export default function useViewer(): ViewerContextValue {
  return useContext(ViewerContext)!;
}
