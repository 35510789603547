import { animated, useSpring, useSprings } from '@react-spring/web';
import { ReactNode, useEffect, useRef } from 'react';

import { makeTabID } from '../../constants/ids';
import { PillVariant } from '../../types/index.js';
import onEnter from '../../utils/onEnter/onEnter';

import styles from './PillGroup.module.scss';

export interface Colors {
  default: string;
  active: string;
}

export interface PillConfig<T> {
  key: T;
  display: ReactNode;
}

interface PillGroupProps<T> {
  pills: PillConfig<T>[];
  value: T;
  onChange(newValue: T): void;
  pillVariant: PillVariant;
  colors: Colors;
}

export default function PillGroup<T extends string>({
  pills,
  value,
  onChange,
  pillVariant,
  colors,
}: PillGroupProps<T>) {
  const pillAnimations = useSprings(
    pills.length,
    pills.map((p) => ({
      color: p.key === value ? colors.active : colors.default,
    }))
  );

  const pillRefs = useRef({} as Record<T, HTMLDivElement | null>);
  const [activeAnimation, animate] = useSpring(() => ({
    from: { left: 0, height: 0, width: 0 },
  }));

  useEffect(() => {
    const pill = pillRefs.current[value];
    if (pill) {
      animate.start({
        left: pill.offsetLeft,
        width: pill.clientWidth,
        height: pill.clientHeight,
      });
    }
  }, [animate, value]);

  if (pills.length === 1) return null;

  return (
    <div className={`${styles[pillVariant + '-container']}`}>
      <animated.div
        style={activeAnimation}
        className={`${styles[pillVariant + '-active']}`}
      />
      {pills.map((p, i) => (
        <animated.div
          id={makeTabID(p.key)}
          style={pillAnimations[i]}
          key={p.key}
          role="button"
          className={`${styles[pillVariant + '-pill']}`}
          onClick={() => onChange(p.key)}
          onKeyDown={onEnter(() => {
            onChange(p.key);
          })}
          data-cy={'pill-' + p.key}
          ref={(r) => {
            pillRefs.current[p.key] = r;
          }}
          tabIndex={0}
        >
          {p.display}
        </animated.div>
      ))}
    </div>
  );
}
