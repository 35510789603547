import { Tour } from '../../types';

export const normalizeTour = (
  tour: Tour
): Panoskin.MountOptions['datafeed'] => ({
  _id: tour.streetViewTour._id,
  client: tour.client._id,
  islands: tour.streetViewTour.islands.map((island) => ({
    ...island,
    scenes: island.scenes
      .map((scene) => ({
        ...scene,
        google: {
          ...scene.google,
          photoId: undefined, // TODO temporarily remove Google stuff to not break tour,
          connections: [], // TODO temporarily remove Google stuff to not break tour
        },
        links: tour.navLinks?.some((navLink) => navLink._id === scene._id)
          ? tour.navLinks?.find((navLink) => navLink._id === scene._id)!.links
          : scene.links,
      }))
      .filter((scene) => (scene.res || []).length > 0),
  })),
});
