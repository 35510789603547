import { useState } from 'react';

import { Theme } from '../../../../types/theme';
import { Tour } from '../../../../types';
import { useAppSelector } from '../../../../hooks/redux';
import { useFullscreen } from '../../../../hooks/useFullscreen/useFullscreen';
import { VIEW_CONTROLS_ID } from '../../../../constants/ids';
import AutoRotate from '../../../../components/AutoRotate/AutoRotate';
import Fullscreen from '../../../LivcorTheme/components/ViewControls/Fullscreen';
import MobileMenu from '../MobileMenu/MobileMenu';
import useIsMobile from '../../../../hooks/useIsMobile/useIsMobile';
import useTourFunctionality from '../../../../hooks/useTourFunctionality/useTourFunctionality';
import useURLParam, {
  OverlayURLParam,
} from '../../../../hooks/useURLParam/useURLParam';

import { ReactComponent as AutorotatePause } from './assets/autorotatePause.svg';
import { ReactComponent as AutorotatePlay } from './assets/autorotatePlay.svg';

import styles from './ViewControls.module.scss';

interface ViewControlsProps {
  tour: Tour;
  theme: Theme;
}

export default function ViewControls({ theme, tour }: ViewControlsProps) {
  const media = useAppSelector((s) => s.media);
  const { availableButtons, hasFullscreenButton } = useTourFunctionality(
    tour,
    theme
  );
  const [autoRotateEnabled, setAutoRotateEnabled] = useState(tour.autoRotate);
  const { isFullscreenEnabled } = useFullscreen();
  const isMobile = useIsMobile();
  const isMobilePortrait = useIsMobile('portrait');
  const hideRightMenu = Boolean(useURLParam(OverlayURLParam.HIDE_RIGHT_MENU));

  return (
    <div className={styles.viewControls} id={VIEW_CONTROLS_ID}>
      {media.type === 'pano' && !isMobilePortrait ? (
        <AutoRotate
          playIcon={AutorotatePlay}
          pauseIcon={AutorotatePause}
          tour={tour}
          enabled={autoRotateEnabled}
          onChange={setAutoRotateEnabled}
        />
      ) : null}
      {!isMobilePortrait && hasFullscreenButton && isFullscreenEnabled ? (
        <Fullscreen />
      ) : null}
      {isMobile && !hideRightMenu ? (
        <MobileMenu tour={tour} menuButtons={availableButtons} />
      ) : null}
    </div>
  );
}
